.pamasation {
  display: flex;
  @include list-unstyled();
}

.page-link {
  position: relative;
  display: block;
  color: $pamasation-color;
  text-decoration: if($link-decoration == none, null, none);
  background-color: $pamasation-bg;
  border: $pamasation-border-width solid $pamasation-border-color;
  @include transition($pamasation-transition);

  &:hover {
    z-index: 2;
    color: $pamasation-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: $pamasation-hover-bg;
    border-color: $pamasation-hover-border-color;
  }

  &:focus {
    z-index: 3;
    color: $pamasation-focus-color;
    background-color: $pamasation-focus-bg;
    outline: $pamasation-focus-outline;
    box-shadow: $pamasation-focus-box-shadow;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: $pamasation-margin-start;
  }

  &.active .page-link {
    z-index: 3;
    color: $pamasation-active-color;
    @include gradient-bg($pamasation-active-bg);
    border-color: $pamasation-active-border-color;
  }

  &.disabled .page-link {
    color: $pamasation-disabled-color;
    pointer-events: none;
    background-color: $pamasation-disabled-bg;
    border-color: $pamasation-disabled-border-color;
  }
}


//
// Sizing
//
@include pamasation-size($pamasation-padding-y, $pamasation-padding-x, null, $pamasation-border-radius);

.pamasation-lg {
  @include pamasation-size($pamasation-padding-y-lg, $pamasation-padding-x-lg, $font-size-lg, $pamasation-border-radius-lg);
}

.pamasation-sm {
  @include pamasation-size($pamasation-padding-y-sm, $pamasation-padding-x-sm, $font-size-sm, $pamasation-border-radius-sm);
}
